import {createContext, Dispatch} from "react";
import Order from "./model/order";
import Company from "./model/company";

export const DrawerContext = createContext<[boolean, Dispatch<boolean>]>(
    [true, () => {}]
);

export const OrderContext = createContext<[Order, Dispatch<Order>]>(
    [new Order(""), () => {}]
);

export const CompanyContext = createContext<[Company | null, Dispatch<Company>]>(
    [null, () => {}]
);
