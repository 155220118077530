import {Nominal} from "./company";

class Contacts {
    name: string = "";
    phone: string = "";
    email: string = "";
}

export default class Order {
    companyId!: string;
    image: string | null = null;
    nominal: Nominal | null = null;
    wishes: string = "";
    toMyself: boolean = false;
    anonymously: boolean = false;
    sender: Contacts = new Contacts();
    receiver: Contacts = new Contacts();
    addedImages: string[] = []

    constructor(companyId: string) {
        this.companyId = companyId;
    }
}
