import React, {ChangeEvent, useContext} from "react";
import Stack from "@mui/system/Stack";
import {BottomBar} from "../widgets/bottom-bar";
import {LeftDrawer} from "../widgets/drawer";
import {TopBar} from "../widgets/top-bar";
import {OrderContext} from "../context";
import TitleText from "../widgets/title-text";
import {TextareaAutosizeCustom, theme} from "../theme";
import {Box, Typography} from "@mui/material";

export function WishesPage() {
    const [order, setOrder] = useContext(OrderContext);
    const onWishesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const o = {
            ...order,
            wishes: event.target.value
        };
        setOrder(o);
    }
    return (
        <Box>
            <Stack>
                <LeftDrawer />
                <TopBar />
                <TitleText
                    title="Добавьте пожелания"
                    sx={{
                        mt: '0.875rem',
                        mx: '2rem',
                        alignSelf: 'start'
                    }}
                />
                <TextareaAutosizeCustom
                    autoFocus
                    value={order.wishes}
                    onChange={onWishesChange}
                    minRows={2}
                    maxLength={40}
                    placeholder="Введите текст"
                    sx={{
                        mt: "0.875rem",
                        mx: "2rem"
                    }}
                />
                <Typography
                    variant="caption"
                    sx={{
                        mt: "0.25rem",
                        mb: "5rem",
                        mx: "2rem",
                        alignSelf: "end"
                    }}
                >
                    {order.wishes.length} / 40
                </Typography>
            </Stack>
            <Box
                maxWidth="md"
                position="fixed"
                bottom="0%"
                width="100%"
                zIndex={theme.zIndex.drawer + 2}
            >
                <BottomBar
                    progress={66}
                    caption="2/3"
                    route="/contacts"
                />
            </Box>
        </Box>
    );
}
