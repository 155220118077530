import {Box, LinearProgress, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {MuiButtonCustom} from "../theme";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Stack from "@mui/system/Stack";
import React from "react";

interface BottomBarProps {
    progress: number;
    caption: string;
    route?: string | null
    onClick?: Function | null
}

export function BottomBar(
    { progress, caption, route = null, onClick = null }: BottomBarProps
) {
    const navigate = useNavigate();
    return (
        <Stack
            direction="row"
            sx={{
                width: "100%",
                pb: "1rem",
                pt: "0.5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
            }}
        >
            <Box
                sx={{
                    width: "100%"
                }}
                pl={4.6}
            >
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        borderRadius: "0.15rem"
                    }}
                />
            </Box>
            <Box
                sx={{ flexShrink: 0 }}
                ml={1}
            >
                <Typography variant="caption">
                    {caption}
                </Typography>
            </Box>
            <Box>
                <MuiButtonCustom
                    disabled={route === null && onClick === null}
                    variant="contained"
                    endIcon={
                        <ArrowForwardIosRoundedIcon
                            style={{ fontSize: "1rem" }}
                        />
                    }
                    style={{
                        marginRight: 8 * 4.6,
                        marginLeft: 12
                    }}
                    onClick={() => {
                        if (route !== null) {
                            navigate(route);
                        } else if (onClick !== null) {
                            onClick();
                        }
                    }}
                >
                    далее
                </MuiButtonCustom>
            </Box>
        </Stack>
    );
}
