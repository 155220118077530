import "./App.css";
import {createMemoryRouter, RouterProvider} from "react-router-dom";
import {WishesPage} from "./pages/wishes";
import {Box, Container, ThemeProvider, Typography} from "@mui/material";
import {theme} from "./theme";
import {CompanyContext, DrawerContext, OrderContext} from "./context";
import React, {useState} from "react";
import Order from "./model/order";
import {ContactsPage} from "./pages/contacts";
import {OrderPage} from "./pages/order";
import {ImageCropperPage} from "./pages/image-cropper";
import {MainPage} from "./pages/main";
import Company from "./model/company";

const router = createMemoryRouter([
    {
        path: "/",
        element: <MainPage />
    },
    {
        path: "/wish",
        element: <WishesPage />
    },
    {
        path: "/contacts",
        element: <ContactsPage />
    },
    {
        path: "/order",
        element: <OrderPage />
    },
    {
        path: "/image_cropper",
        element: <ImageCropperPage />
    }
])

const getParamsFromURI = ( uri: String ) => {
    // Get everything after the `?`
    const [ , paramString ] = uri.split( '?' );

    // Return parameters
    return new URLSearchParams( paramString );
};

function App() {
    const [isDrawerClosed, setIsDrawerClosed] = useState<boolean>(true);
    const companyId = getParamsFromURI(window.location.href).get("gftcmp");
    const [order, setOrder] = useState<Order>(new Order(companyId ?? ""));
    const [company, setCompany] = useState<Company | null>(null);
    if (companyId == null) {
        return (
            <React.Fragment>
                <Container
                    maxWidth="md"
                    sx={{
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <Box
                        sx={{
                            minHeight: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h3"
                            color={theme.palette.primary.main}
                        >
                            Not found
                        </Typography>
                    </Box>
                </Container>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <Container
                disableGutters
                maxWidth="md"
            >
                <Box
                    minHeight="100vh"
                    bgcolor={theme.palette.background.paper}
                >
                    <DrawerContext.Provider
                        value={[isDrawerClosed, setIsDrawerClosed]}
                    >
                        <OrderContext.Provider
                            value={[order, setOrder]}
                        >
                            <CompanyContext.Provider
                                value={[company, setCompany]}
                            >
                                <ThemeProvider theme={theme}>
                                    <RouterProvider router={router} />
                                </ThemeProvider>
                            </CompanyContext.Provider>
                        </OrderContext.Provider>
                    </DrawerContext.Provider>
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default App;
