import React, {ChangeEvent, useContext, useState} from "react";
import Stack from "@mui/system/Stack";
import {BottomBar} from "../widgets/bottom-bar";
import {LeftDrawer} from "../widgets/drawer";
import {TopBar} from "../widgets/top-bar";
import {Box, FormControlLabel, Typography} from "@mui/material";
import {OrderContext} from "../context";
import InputMask from "@mona-health/react-input-mask";
import TitleText from "../widgets/title-text";
import {CheckboxCustom, TextFieldCustom, theme, ToggleButtonCustom, ToggleButtonGroupCustom} from "../theme";
import {useNavigate} from "react-router-dom";

export function ContactsPage() {
    const navigate = useNavigate();

    const [order, setOrder] = useContext(OrderContext);
    const emailRegexp = new RegExp("^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$");
    const phoneRegexp = new RegExp("^((\\+7|\\+8|7|8)*(9)+([0-9]){9})$");

    const [phoneReceiverError, setPhoneReceiverError] = useState<boolean>();
    const [phoneSenderError, setPhoneSenderError] = useState<boolean>();
    const [emailReceiverError, setEmailReceiverError] = useState<boolean>();
    const [emailSenderError, setEmailSenderError] = useState<boolean>();
    const [nameSenderError, setNameSenderError] = useState<boolean>();

    const onDestinationChange = (_: any, value: boolean) => {
        const o = {
            ...order,
            toMyself: value
        }
        setOrder(o);
    };

    const onReceiverNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const o = {
            ...order,
            receiver: {
                ...order.receiver,
                name: event.target.value
            }
        };
        setOrder(o);
    };

    const onReceiverPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value
            .replace(/\s/g, "")
            .replace("(", "")
            .replace(")", "")
            .replace("+7", "");
        if (value.length > 10 && !phoneRegexp.test(value)) {
            return;
        }
        const o = {
            ...order,
            receiver: {
                ...order.receiver,
                phone: value
            }
        };
        setOrder(o);
        if (phoneRegexp.test(value)) {
            setPhoneReceiverError(false);
        }
    };

    const onReceiverEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        const o = {
            ...order,
            receiver: {
                ...order.receiver,
                email: event.target.value
            }
        };
        setOrder(o);
        if (!event.target.value || emailRegexp.test(event.target.value)) {
            setEmailReceiverError(false);
        }
    }

    const onAnonymousChange = (_: ChangeEvent<HTMLInputElement>, value: boolean) => {
        const o = {
            ...order,
            anonymously: value
        }
        setOrder(o);
    };

    const onSenderNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const o = {
            ...order,
            sender: {
                ...order.sender,
                name: event.target.value
            }
        };
        setOrder(o);
        if (event.target.value.length > 1) {
            setNameSenderError(false);
        }
    };

    const onSenderPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value
            .replace(/\s/g, "")
            .replace("(", "")
            .replace(")", "")
            .replace("+7", "");
        if (value.length > 10 && !phoneRegexp.test(value)) {
            return;
        }
        const o = {
            ...order,
            sender: {
                ...order.sender,
                phone: value
            }
        };
        setOrder(o);
        if (phoneRegexp.test(value)) {
            setPhoneSenderError(false);
        }
    };

    const onSenderEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        const o = {
            ...order,
            sender: {
                ...order.sender,
                email: event.target.value
            }
        };
        setOrder(o);
        if (!event.target.value || emailRegexp.test(event.target.value)) {
            setEmailSenderError(false);
        }
    };

    const onNextClick = () => {
        let hasError = false;
        if (!phoneRegexp.test(order.sender.phone)) {
            setPhoneSenderError(true);
            hasError = true;
        }
        if (!emailRegexp.test(order.sender.email)) {
            setEmailSenderError(true);
            hasError = true;
        }
        if (order.sender.name.length < 2) {
            setNameSenderError(true);
            hasError = true;
        }
        if (!order.toMyself) {
            if (!phoneRegexp.test(order.receiver.phone)) {
                setPhoneReceiverError(true);
                hasError = true;
            }
            if (order.receiver.email && !emailRegexp.test(order.receiver.email)) {
                setEmailReceiverError(true);
                hasError = true;
            }
        }
        if (hasError) {
            return;
        }
        navigate("/order");
    };

    return (
        <Box>
            <Stack>
                <LeftDrawer />
                <TopBar />
                <Stack
                    height="100%"
                    textAlign="center"
                    sx={{
                        display: "flex"
                    }}
                >
                    <TitleText
                        title="Кому отправить?"
                        sx={{
                            mt: '0.875rem',
                            mx: '2rem',
                            alignSelf: 'start'
                        }}
                    />
                    <ToggleButtonGroupCustom
                        color="primary"
                        value={order.toMyself}
                        exclusive
                        onChange={onDestinationChange}
                        sx={{
                            mt: "1rem",
                            mx: "2rem"
                        }}
                    >
                        <ToggleButtonCustom
                            value={false}
                            fullWidth
                            size="small"
                            sx={{
                                mr: "0.3rem",
                                ml: "0.3rem"
                            }}
                        >
                            В подарок
                        </ToggleButtonCustom>
                        <ToggleButtonCustom
                            value={true}
                            fullWidth
                            size="small"
                            sx={{
                                mr: "0.3rem"
                            }}
                        >
                            Себе
                        </ToggleButtonCustom>
                    </ToggleButtonGroupCustom>
                    {
                        order.toMyself ? null : <TextFieldCustom
                            variant="standard"
                            label="Имя получателя"
                            value={order.receiver.name}
                            onChange={onReceiverNameChange}
                            sx={{
                                mt: "1rem",
                                mx: "2rem"
                            }}
                        />
                    }
                    {
                        order.toMyself ? null : <InputMask
                            mask="+7 (999) 999 99 99"
                            maskPlaceholder=" "
                            value={order.receiver.phone}
                            onChange={onReceiverPhoneChange}
                            style={{
                                marginTop: "0.5rem",
                                marginLeft: "2rem",
                                marginRight: "2rem"
                            }}
                        >
                            <TextFieldCustom
                                variant="standard"
                                label="Телефон получателя *"
                                error={phoneReceiverError}
                                helperText={phoneReceiverError ? "Пожалуйста, введите корректный номер телефона" : ""}
                            />
                        </InputMask>
                    }
                    {
                        order.toMyself ? null : <TextFieldCustom
                            variant="standard"
                            label="E-mail получателя"
                            error={emailReceiverError}
                            helperText={emailReceiverError ? "Пожалуйста, введите корректный e-mail адрес" : ""}
                            value={order.receiver.email}
                            onChange={onReceiverEmailChange}
                            sx={{
                                mt: "0.5rem",
                                mx: "2rem"
                            }}
                        />
                    }
                    {
                        order.toMyself ? null : <Box
                            textAlign="left"
                            sx={{
                                mt: "1.5rem",
                                mx: "2rem"
                            }}
                        >
                            ОТ КОГО?
                        </Box>
                    }
                    {
                        order.toMyself ? null : <FormControlLabel
                            sx={{
                                mr: "2rem",
                                ml: "1.3rem",
                                mt: "0.5rem",
                                fontSize: "0.8125rem",
                                fontWeight: 600,
                                color: "#2A94FF"
                            }}
                            control={
                                <CheckboxCustom
                                    checked={order.anonymously}
                                    onChange={onAnonymousChange}
                                />
                            }
                            label={
                                <Typography variant="h3">
                                    Отправить анонимно
                                </Typography>
                            }
                        />
                    }
                    <TextFieldCustom
                        sx={{
                            mx: "2rem"
                        }}
                        variant="standard"
                        label="Ваше имя *"
                        error={nameSenderError}
                        helperText={nameSenderError ? "Пожалуйста, введите имя" : ""}
                        value={order.sender.name}
                        onChange={onSenderNameChange}
                    />
                    <InputMask
                        style={{
                            marginTop: "0.5rem",
                            marginLeft: "2rem",
                            marginRight: "2rem"
                        }}
                        mask="+7 (999) 999 99 99"
                        maskPlaceholder=" "
                        value={order.sender.phone}
                        onChange={onSenderPhoneChange}
                    >
                        <TextFieldCustom
                            variant="standard"
                            label="Ваш телефон *"
                            error={phoneSenderError}
                            helperText={phoneSenderError ? "Пожалуйста, введите корректный номер телефона" : ""}
                        />
                    </InputMask>
                    <TextFieldCustom
                        sx={{
                            mt: "0.5rem",
                            mx: "2rem",
                            mb: "5rem"
                        }}
                        variant="standard"
                        label="Ваш E-mail *"
                        error={emailSenderError}
                        helperText={emailSenderError ? "Пожалуйста, введите корректный e-mail адрес" : ""}
                        value={order.sender.email}
                        onChange={onSenderEmailChange}
                    />
                </Stack>
            </Stack>
            <Box
                maxWidth="md"
                position="fixed"
                bottom="0%"
                width="100%"
                zIndex={theme.zIndex.drawer + 2}
            >
                <BottomBar
                    progress={100}
                    caption="3/3"
                    onClick={onNextClick}
                />
            </Box>
        </Box>
    );
}
